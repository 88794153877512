html, body{
    margin: 0;
    padding: 0;
}
*{
    box-sizing: border-box;
    font-family: 'Source Sans Pro', sans-serif;
}
body.modal-open {
    overflow: hidden;
}
.App {
    background: #f5f5f5;
    padding: 15px;
}
.app-container {
    display: flex;
    flex-wrap: wrap;
}
.groups-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000bf;
    z-index: 99;
}
.groups-modal-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 50px 0;
    overflow: auto;
}
.groups-modal-content {
    display: block;
    width: 400px;
    background: #fff;
    padding: 5px;
}
.groups-modal-close {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 30px;
    cursor: pointer;
    text-shadow: 0 0 5px #fff;
    color: #fff;
}
.group-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #cbcbcb;
    background: #eaeaea;
}
.scenarios-container {
    border: 1px solid #006912;
    border-radius: 15px;
    background: #fff;
}
.scenario-block {
    position: relative;
    border-bottom: 1px solid #ccc;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.scenario-block.opened-true {
    background: #efefef;
}
.groups-container {
    margin-right: 35px;
}
.addToGroups, .joinToGroup{
    cursor: pointer;
}
.joinToGroup i {
    margin-left: 10px;
}
.addToGroups, .groupExists, .groups-modal-close {
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
}
.addToGroups:before {
    content: "\f067";
}
.groupExists:before {
    content: "\f00c";
}
.groups-modal-close:before {
    content: "\f00d";
}
.groups-block {
    position: relative;
    min-height: 300px;
    border: 1px solid #006912;
    border-radius: 15px;
    margin-bottom: 15px;
    background: #fff;
}
.groups-block h2,
.scenarios-container h2 {
    position: relative;
    margin: 0;
    background: #006912;
    color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 20px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}
.group-scenarios {
    width: calc(100% - 20px);
    min-height: calc(100% - 55px);
    border: 1px solid #ccc;
    margin: 10px;
    border-bottom: 0;
}
.group-scenarios li {
    list-style-type: none;
    border-bottom: 1px solid #ccc;
    padding: 5px;
    font-size: 14px;
}
.move-scenario-modal {
    position: fixed;
    background: red;
    z-index: 9;
}
.appButtons {
    display: flex;
    align-items: center;
}
.get-gropus, .remove-cache {
    background: #006912;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 180px;
    padding: 10px 15px;
    margin-bottom: 20px;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    user-select: none;
}
.remove-cache {
    margin-left: 50px;
    justify-content: start;
    width: 156px;
    background: #c20000;
}
.remove-cache i {
    margin-right: 20px;
}
.moveToGroup {
    position: absolute;
    top: 100%;
    right: 0;
    background: #efefef;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px #ccc;
    display: flex;
    flex-direction: column;
    z-index: 9;
    user-select: none;
}
.moveToGroup span {
    padding: 5px;
    border-bottom: 1px solid #fff;
    cursor: pointer;
}
.moveToGroup span:hover {
    background: #ccc;
}
.app-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.app-loader.loader {
    background: #000;
}
.app-loader.progress {
    background: #00000070;
}
.app-loader.show-false {
    display: none;
}
.groups-block h2 i {
    position: absolute;
    right: 10px;
    cursor: pointer;
}
.authorizationForm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #68bc4c;
}
.authorizationForm form {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 30px;
    box-shadow: 0 0 5px #535353;
}
.authorizationForm input {
    padding: 15px;
    margin-bottom: 15px;
    width: 280px;
    background: #f1f3f2;
    border: none;
}
.authorizationForm button {
    background: #00b143;
    border: none;
    padding: 15px;
    color: #fff;
    cursor: pointer;
}
.group-item .groupExists {
    color: #00c000;
}
.group-item .addToGroups {
    color: #f33e3e;
}
